import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import checked from "../../assets/img/checked.gif"
import { useSelector } from 'react-redux';
import { set } from 'lodash';
export default function OrderSuccessfull({ open, setOpen, handleCloseModal }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [itemCount, setItemCount] = useState(0);
    const itemCounts = useSelector((state) => state?.filterCartReducer?.result?.item_count);
    const selectedCluster = location.state?.selectedCluster || localStorage.getItem("selectedCluster");
   
    const clusterId= localStorage.getItem("selectedClusterForOrder")

    useEffect(() => {
        if (itemCounts !== undefined) {
            setItemCount(itemCounts);
        }
    }, [itemCounts]);

    const handleBack = () => {  
        navigate('/view-order', { state: { selectedOrder: clusterId, selectedCluster } });
    };

    return (
        <>
            <Modal open={open} onClose={handleCloseModal}>
                <Card className='modal' sx={{paddingBlock:"50px"}}>
                    <Stack spacing={2}>
                        <Stack alignItems='center'>
                            <img width={82} height={82} src={checked} />
                            <Typography variant='h5' className='text-center' fontWeight={600}> Your order has been successfully placed for {itemCount} items</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' sx={{ my: 2 }} justifyContent='center'>
                        <Button variant='contained' onClick={handleBack}>Ok</Button>
                    </Stack>
                </Card>
            </Modal>
        </>
    )
}

