import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles"; // Changed import to use curly braces for makeStyles
import Payment from "./Payment";
import { sendConsent } from "../../store/layout/sendConsent";
import { convertToFormData } from "../ConvertToFormData";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../toast/toast";

const useStyles = makeStyles((theme) => ({
  bgLightGray: {
    backgroundColor: "rgba(213, 222, 229, 1)",
  },
  borderRadius10: {
    borderRadius: "10px",
  },
  p10: {
    padding: "10px",
  },
  p20: {
    padding: "20px",
  },
  buttonsStyle: {
    backgroundColor: "#D7EDFE",
  },
  fullWidth: {
    width: "100%",
  },
}));

const ConsentMessage = ({ open, handleClose, schematicData }) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const inputRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const handlePaymentOpen = () => setOpenModal(true);
  const handlePaymentClose = () => setOpenModal(false);

  const [checkedState, setCheckedState] = useState({
    noFurtherChanges: false,
    understoodNorms: false,
  });

  const handleCheckboxChange = (event) => {
    setCheckedState({
      ...checkedState,
      [event.target.name]: event.target.checked,
    });
  };

  const isButtonDisabled = !(checkedState.noFurtherChanges && checkedState.understoodNorms);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>

          <CardHeader
            className="popup-header-rev"
            action={
              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            }
            title="Consent Message"
          />
          <CardContent className="d-flex flex-column mx-auto" sx={{ maxWidth: "500px", width: "auto" }}>
            <Typography variant="h4" className="text-center fs-14 fw-400">Please read the below message carefuly before proceeding</Typography>
            <TextareaAutosize style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px", marginTop: "10px" }} className="bg-white mx-auto"
              placeholder="Type in here…"
              disabled
              error
              defaultValue="I carefully reviewed the schematic layout and no further revisions are
required. I request Kia India to prepare & share the final GFC (Good For
Construction) layout alongwith material quantity to be ordered for
construction & facility completion. This is to state that I will not request
any further ammends/changes in approved schematic Layout."
            />
            <FormGroup>
              <FormControlLabel control={<Checkbox name="noFurtherChanges" checked={checkedState.noFurtherChanges} onChange={handleCheckboxChange} />} label="I approve the Schematic Layout shared by Kia India" />
              <FormControlLabel required control={<Checkbox name="understoodNorms" checked={checkedState.understoodNorms} onChange={handleCheckboxChange} />} label="In case of changes requested/proposed henceforth in approved Schematic Layout or the GFC, I will be
paying the necessary charges to get the ammends done" />
            </FormGroup>
          </CardContent>
          <CardActions disableSpacing>
            <Box component={"div"}
              width={"100%"}
              marginBlockStart={0.5}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={5}>

              <Button
                variant="contained"
                onClick={handlePaymentOpen}
                disabled={isButtonDisabled}
              >
                Submit
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Modal>
      <Payment open={openModal} handleClose={handlePaymentClose} schematicData={schematicData}  closeParentModal={handleClose} />
    </>
  );
};

export default ConsentMessage;
