import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography } from "@mui/material";
import { gfcRevised } from '../../store/layout/gfcRevised';
import { useDispatch, useSelector } from 'react-redux';
import { convertToFormData } from '../ConvertToFormData';
import { postRevisedGFCView } from '../../store/layout/postRevisedGFCView';
import Loader from '../reusable/Loader';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function GfcRevisedLayout({setSelectedTab}) {
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    const loading = useSelector((state) => state?.gfcRevisedReducer?.loading);
    const dispatch = useDispatch()
    const [data, setData] = useState([]);
    const getGfcLayout = async () => {
        const formD = {
            'form_id': formId?.id
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(gfcRevised(data));
            console.log(response, "response");
            setData(response?.payload?.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getGfcLayout();
    }, []);

    if (loading) {
        return <Loader/>;
    }

    const handleView = async (data) => {
        try {
            localStorage.setItem("isRevisedGFCViewClick", 1);
            await dispatch(postRevisedGFCView(data));
            setSelectedTab(7)
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Typography variant="h6" className="fs-17  fw-400">GFC Revisions</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box className="d-flex flex-wrap" sx={{ gap: "9px" }}>
                        <Button className='layout-blue-btn'>
                            GFC by KIA
                        </Button>
                        <Button className='layout-lightblue-btn'>
                            Request from Dealer
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>GFC Send Date</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.length >0 ?(
                                    <>
                                      {data?.map((row) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell sx={{paddingBlock: "5px !important"}}>{row.created_at.split("T")[0]}</StyledTableCell>
                                        <StyledTableCell sx={{paddingBlock: "5px !important"}} align="center" >
                                            <Button className="DownloadBtn" sx={{marginRight: "15px" }}>Download</Button>

                                            <Button className="DownloadBtn"onClick={() =>handleView(row)}>View</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))} 
                                </>): (   <StyledTableCell sx={{paddingBlock: "5px !important"}}>No data found</StyledTableCell>)}
                               
                               
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}