import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, IconButton, Modal, Stack, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import ResetPassword from './ResetPassword';
import { useDispatch, useSelector } from 'react-redux';
import { verifyAuthOtpApi } from '../../store/auth/verifyAuthOtp';
import { authOtpViaEmailApi } from "../../store/auth/authOtpViaEmail"
import ModalCss from "../../styles/modal.module.scss";
import { showToast } from '../../toast/toast';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { forgetPasswordViaEmailApi } from '../../store/auth/forgetPasswordViaEmail';

export default function ResetViaEmailOTP({ open, setOpen, openPreviousModal, handleCloseP, handleSendLink }) {
  const dispatch = useDispatch()
  const [otp, setOtp] = useState('')
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const forgetPasswordData = useSelector(state => state.forgetPasswordViaEmail);
  const user_type = "Dealor"
  const email = localStorage.getItem("email")
  const access_token = forgetPasswordData?.result?.access_token ? forgetPasswordData?.result?.access_token : localStorage.getItem("access_token")
  const dealorEmail = forgetPasswordData?.result?.email ? forgetPasswordData?.result?.email : localStorage.getItem("email")

  let maskedEmail = "";
  if (email) {
    const [localPart, domain] = email.split('@');
    maskedEmail = `${localPart.slice(0, 3)}*****@${domain}`;
  }


  const handleClose = () => {
    setOtp("")
    setOpen(false)
    handleCloseP()
  }

  const handleVerify = async () => {
    const verifyData = await dispatch(verifyAuthOtpApi({ otp: Number(otp), user_type, type: "email", email: email }, { headers: { Authorization: `Bearer ${access_token}` } }))
    if (!verifyData?.payload?.error) {
      localStorage.setItem("access_token", verifyData?.payload?.result?.access_token)
      setResetPasswordModal(true)
      handleClose()
    } else {
      showToast(verifyData?.payload?.message, 2)
    }
  }

  // const handleResendOtp = () => {
  //   dispatch(authOtpViaEmailApi({ type: "email", user_type, email: email, }))
  // }

  const handleResendOtp = async() => {
    // dispatch(authOtpViaEmailApi({ type: "email", user_type, email: email, }))
    setOtp("")
    const responseForgetPassword = await dispatch(forgetPasswordViaEmailApi({ type: "email", user_type, email: dealorEmail }));
      if(!responseForgetPassword?.payload?.error){
        const response = dispatch(authOtpViaEmailApi({ type: "email", user_type, email:dealorEmail, "forget_password" : "forget_password" }));
        showToast(responseForgetPassword?.payload?.message, 1)
     }
    
  }

  const handleBack = () => {
    setOtp("")
    openPreviousModal(true)
    handleClose()
  }

  return (
    <>
      <Modal open={open} onClose={handleClose} className={ModalCss?.customModal}>
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <Stack spacing={2} p={3} position={"relative"}>
            <Stack
              direction="row"
              justifyContent="space-between" 
              alignItems="center"
              position={"absolute"}
              left={5}  
              right={5}
              top={5}   
              width="100%"
            >
              <IconButton onClick={handleBack}>
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            </Stack>
            <Stack alignItems='center'>
              <Typography variant='h5' fontWeight={600}>Verify OTP</Typography>
              <Typography sx={{ textAlign: 'center' }}>{`We have sent an OTP to your registered email address ${maskedEmail}`}</Typography>
            </Stack>
            <OtpInput
              containerStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBlock: "50px 30px",
              }}
              inputStyle={{
                width: 72,
                height: 72,
                fontSize: "20px",
                border: "2px solid black",
                borderRadius: "10px",
              }}
              value={otp}
              onChange={setOtp}
              // onPaste={handlePaste}

              inputType="number"
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => (
                <input
                  {...props}
                  type="tel"
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              )}
              isInputNum={true}
            />

            <Box sx={{ textAlign: "center" }}>
              <Button sx={{
                marginTop: "20px !important",
                width: "80%",
                padding: { lg: "20px 10px", xs: "10px" },
                fontSize: { lg: "18px", xs: "14px" },
                fontWeight: { lg: "500", xs: "500" },
                borderRadius: "10px",
              }} variant='contained' onClick={handleVerify}     disabled={otp?.length < 4}>Verify Account</Button>
            </Box>
            <Stack justifyContent="center" direction="row" alignItems="center" spacing={2} fontSize={'18px'} marginTop={'30px'}>
              <Typography fontSize={'inherit'}>Didn't receive the OTP?</Typography>
              <Button sx={{ fontSize: "16px", fontWeight: "600" }} onClick={() => handleResendOtp()} className="black-btn">Resend</Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>

      <ResetPassword open={resetPasswordModal} setOpen={setResetPasswordModal} openPreviousModal={setOpen} />
    </>
  )
}
